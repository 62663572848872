/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core'
import Page, { PageMeta } from 'components/Layout/Page'
import { useTranslation } from 'contexts/Localization'
import React, { useState, useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import styled from 'styled-components'
import Berth from './Berth'
import { Button, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { AbiConfig, Constants } from 'hooks/WolfConfig'
import CopyAddress from '../../components/Menu/UserMenu/CopyAddress'
import { getContractHandler } from 'hooks/ethereum'
import { useCurrentBlock } from 'state/block/hooks'
import VideoPi from './VideoPi'
import useToast from 'hooks/useToast'
import Script from 'react-load-script'
import { parseUnits, formatEther } from '@ethersproject/units'

const StyledPage = styled(Page)`
  background-color: #060818;
  width: 100%;
`
const StyledText = styled(Text)`
  width: 100%;
`

const StyledButton = styled(Button)`
  width: 200px;
  display: table;
  margin: 0 auto;
  margin-top: 40px;
  background: linear-gradient(270deg, rgb(51, 212, 250) 0%, rgb(23, 243, 221) 100%);
  box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.1);
`

const StyledDic = styled.div`
  width: 300px;
  display: table;
  margin: 0 auto;
  margin-top: 40px;
`

const StyledDiv = styled.div`
  display: table;
  margin: 0 auto;
  width: 80%;
  border: 1px solid rgb(30, 39, 64);
  border-radius: 16px;
  margin-top: 50px;
`

const StyledDiv1 = styled.div`
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  font-family: ExtraBold;
  padding-left: 24px;
  line-height: 56px;
  min-height: 56px;
`

const StyledDiv2 = styled.div`
  background-color: rgb(30, 39, 64);
  height: 1px;
  margin: 0px auto 32px;
  width: 100%;
`
const StyledDiv3 = styled.div`
  padding: 0px 0px 24px 0px;
  min-height: 72px;
`
const StyledDiv4 = styled.div`
  padding: 0px 0px 24px 0px;
  min-height: 130px;
`

const StyledDivii = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`
const StyledDiviii = styled.div`
  min-width: 170px;
`

const StyledDiviiii = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10px;
`
const BertDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
`

const Home: React.FC = () => {
  const { account } = useWeb3React()
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()

  const [isLoadding, setIsLoadding] = useState(false)

  const [invite1, setInvite1] = useState('0.00')
  const [invite2, setInvite2] = useState('0.00')
  const [invite3, setInvite3] = useState('0.00')

  const { toastSuccess, toastError } = useToast()
  const currentBlock = useCurrentBlock()
  const reward = async () => {
    try {
      const pi = await getContractHandler('BRX')
      if (!pi) return
      setIsLoadding(true)

      await pi.receiveRewards()
      toastSuccess('Successfully!')

      setIsLoadding(false)
    } catch (e: any) {
      setIsLoadding(false)
      console.log('e?.messagee?.messagee?.message', e)
      if (e?.data?.message) {
        toastError(e?.data?.message)
      } else if (e?.message) {
        toastError(e?.message)
      }
    }
  }

  useEffect(() => {
    if (account) {
      queryLPStakeInfo()
    }
  }, [account, Math.floor(currentBlock / 3)])

  const queryLPStakeInfo = async () => {
    //查询LP信息  Constants.Contract.NEWGODZ
    const pi = await getContractHandler('BRX')

    if (!pi) return
    if (!account) return
    console.log('66666666666opppppppp')
    const mylps1 = await pi.inviteFee(account, '0')
    const mylps2 = await pi.inviteFee(account, '1')
    const mylps3 = await pi.inviteFee(account, '2')

    console.log('mylps3mylps3mylps3', mylps3)

    setInvite1(parseFloat(formatEther(mylps1)).toFixed(8))
    setInvite2(parseFloat(formatEther(mylps2)).toFixed(8))
    setInvite3(parseFloat(formatEther(mylps3)).toFixed(8))
  }

  return (
    <>
      <StyledPage>
        <StyledText mt={isMobile ? 100 : 50} fontSize={'20px'} textAlign={'center'}>
          {t('Introduction to PIDAO')}
        </StyledText>
        <StyledText fontSize={'20px'} mt={50} textAlign={'center'}>
          {t('PIDAO is a coalition of over a dozen communities.')}
          {t(
            'Deploy a decentralized exchange platform token on Kulchain to create the strongest Defi joint venture in Kulchain, and open source Pailchain will cross the Pailchain.',
          )}
          {t('A total of 314159 pieces, no private placement, 100% fair launch!')}
        </StyledText>
        <BertDiv>
          {/*  <Berth /> */}

          <VideoPi />
        </BertDiv>

        <StyledButton
          onClick={() => {
            window.open(Constants.BUYURL, '_blank')
          }}
        >
          Buy Now
        </StyledButton>
        <StyledDic>
          <CopyAddress account={Constants.Contract.BRX} mb="24px" />
        </StyledDic>

        <StyledDiv>
          <StyledDiv1>{t('Token allocation')}</StyledDiv1>
          <StyledDiv2></StyledDiv2>
          <StyledDiv3>
            <StyledDivii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Token Total')}</Text>
                <Text textAlign={'center'}>314159</Text>
              </StyledDiviii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Add LP')}</Text>
                <Text textAlign={'center'}>300000</Text>
              </StyledDiviii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Airdrop')}</Text>
                <Text textAlign={'center'}>14159</Text>
              </StyledDiviii>
            </StyledDivii>
          </StyledDiv3>
        </StyledDiv>

        <StyledDiv>
          <StyledDiv1>{t('Transaction tax')}</StyledDiv1>
          <StyledDiv2></StyledDiv2>
          <StyledDiv3>
            <StyledDivii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Marketing')}</Text>
                <Text textAlign={'center'}>1 %</Text>
              </StyledDiviii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Destruction')}</Text>
                <Text textAlign={'center'}>0.1%</Text>
              </StyledDiviii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('reflux')}</Text>
                <Text textAlign={'center'}>0.1%</Text>
              </StyledDiviii>

              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('LP dividend')}</Text>
                <Text textAlign={'center'}>4%</Text>
              </StyledDiviii>

              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Third level invitation')}</Text>
                <Text textAlign={'center'}>0.9%</Text>
              </StyledDiviii>
            </StyledDivii>
          </StyledDiv3>
        </StyledDiv>

        <StyledDiv>
          <StyledDiv1>{t('Invitation')}</StyledDiv1>
          <StyledDiv1>
            {t(
              'Any number of airdrops is a binding relationship, and rewards will be claimed on the core official website',
            )}
          </StyledDiv1>
          <StyledDiv2></StyledDiv2>
          <StyledDiv3>
            <StyledDivii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('First level invitation')}</Text>
                <Text textAlign={'center'}>0.5%</Text>
              </StyledDiviii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Second level invitation')}</Text>
                <Text textAlign={'center'}>0.2%</Text>
              </StyledDiviii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Third level invitation')}</Text>
                <Text textAlign={'center'}>0.2%</Text>
              </StyledDiviii>
            </StyledDivii>
          </StyledDiv3>
        </StyledDiv>

        <StyledDiv>
          <StyledDiv1>{t('Invitation rewards')}</StyledDiv1>
          <StyledDiv2></StyledDiv2>
          <StyledDiv3>
            <StyledDivii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('First level reward')}</Text>
                <Text textAlign={'center'}>{invite1}</Text>
              </StyledDiviii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Second level reward')}</Text>
                <Text textAlign={'center'}>{invite2}</Text>
              </StyledDiviii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Text textAlign={'center'}>{t('Third level reward')}</Text>
                <Text textAlign={'center'}>{invite3}</Text>
              </StyledDiviii>
              <StyledDiviii style={{ marginTop: '24px' }}>
                <Button disabled={isLoadding} variant={'secondary'} ml={20} onClick={reward}>
                  {t('Claim income')}
                </Button>
              </StyledDiviii>
            </StyledDivii>
          </StyledDiv3>
        </StyledDiv>
      </StyledPage>
    </>
  )
}

export default Home
